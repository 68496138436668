import React, { useState } from "react";
import { connect } from 'react-redux';
import { ReactComponent as LogoBlika } from '../img/logo_blika.svg';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LanguageIcon from '@mui/icons-material/Language';
import ExpandIcon from '@mui/icons-material/Expand';
import LogoutIcon from '@mui/icons-material/Logout';
import Flag from 'react-world-flags';
import Cruces from '../components/Cruces';
import Regiones from '../components/Regiones';
import Meses from "../components/Meses";

const Footer = ({ loggedstate, idioma, salir }) => {
    const [current, setCurrent] = useState(false);

    const abrirPaneles = (e) => {
        e.preventDefault();
        document.querySelectorAll('.panel').forEach(e => e.classList.remove("activo"));
        var id = e.currentTarget.dataset.panel;
        var element = document.getElementById(id);
        setTimeout(function () {
            element.classList.add("activo");
        }, 500);
    }

    const cerrar = (e) => {
        e.currentTarget.closest(".panel").classList.remove("activo");
    }

    return (
        <>
            <div id="footer">
                <div id="sucursales">
                    <button id="bregiones" type="button" data-panel="panregiones" onClick={abrirPaneles} >{(loggedstate.vars.region === false) ? ('TODAS LAS REGIONES') : (loggedstate.vars.nombreregion)}<ExpandIcon /></button>
                </div>
                <Meses />
                <div id="foot">
                    <div id="logos">
                        <LogoBlika id="logo" />
                        <div id="logocliente">
                            {(loggedstate.vars.clienteid) ? (<img className="logo logocliente" src={"https://blika.mx/uploads/logos/logo_" + loggedstate.vars.clienteid + ".jpg"} />) : (undefined)}
                        </div>
                    </div>
                    <div id="menu">
                        {/*<button id="ayuda" type="button" data-panel="panhelp" onClick={abrirPaneles}><HelpCenterIcon /></button>
                        <button id="idioma" type="button" data-panel="panlanguage" onClick={abrirPaneles}><LanguageIcon /></button>*/}
                        <button id="cruces" type="button" data-panel="pancruce" onClick={abrirPaneles}><AnalyticsIcon /></button>
                        {' '}{' '}
                        <button id="salir" type="button" onClick={salir}><LogoutIcon /></button>
                    </div>
                </div>
            </div>
            <div id="panhelp" className="panel">
                <button className="cerrar" type="button" onClick={cerrar}>X</button>
                Panel de ayuda
            </div>
            <div id="panregiones" className="panel">
                <button className="cerrar" type="button" onClick={cerrar}>X</button>
                <Regiones />
            </div>
            <div id="panlanguage" className="panel">
                <button className="cerrar" type="button" onClick={cerrar}>X</button>
                <ul>
                    <li className={(loggedstate.vars.idioma == 'es') ? ('activo') : ('')}><button onClick={(e) => idioma('es')} type="button"><Flag code={"MX"} /> {loggedstate.vars[loggedstate.vars.idioma].botoneslang.es} </button></li>
                    <li className={(loggedstate.vars.idioma == 'en') ? ('activo') : ('')}><button onClick={(e) => idioma('en')} type="button"><Flag code={"USA"} /> {loggedstate.vars[loggedstate.vars.idioma].botoneslang.en} </button></li>
                </ul>
            </div>
            <div id="pancruce" className="panel">
                <button className="cerrar" type="button" onClick={cerrar}>X</button>
                <Cruces />
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    loggedstate: state
})

const mapDispatchToProps = (dispatch) => {
    return {
        idioma: (idioma) => dispatch({
            type: 'SET_LANG', idioma: idioma
        }),
        salir: () => {
            console.log('salir function called');
            dispatch({ type: 'LOG_OUT' });
        },
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);