import React, { Component, Fragment } from "react";
import IconPhoto from '@mui/icons-material/InsertPhoto';
class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgindex: 0,
            imgurl: this.props.galeria[0]
        };
    }
    render() {
        return (
            <div className="galeria">
                <button className="cerrar" onClick={this.props.handler} type="button">X</button>
                <img src={this.state.imgurl} />
                <div className="botonera">
                    {this.props.galeria.map((img, index) => (
                        <button key={index} className={"foto archivosico " + ((this.state.imgindex == index) ? ('activo') : (''))} type="button" onClick={() => this.setState({ imgindex: index, imgurl: img })}><IconPhoto /></button>
                    ))}
                </div>
            </div>
        )
    }

}

export default Gallery;