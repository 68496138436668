import React, { Component} from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
  flex: 1,
  height: "auto",
  width: "100%"
};

class Pantalla6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      labels: [],
    };
  }

  peticionAxios() {
    let formData = new FormData();
    formData.append('pantalla', 6);
    formData.append('clienteid', this.props.loggedstate.vars.clienteid);
    formData.append('usuario', this.props.loggedstate.login.user);
    formData.append('permiso', this.props.loggedstate.login.permiso);
    formData.append('region', this.props.loggedstate.vars.region);
    formData.append('mes', this.props.loggedstate.vars.mes);
    let generales
    let datos = [];
    let labels = [];
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'resultados_generales.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        generales = data[0].pantalla6;

        generales.map(tienda => {
          datos.push(tienda.value);
          const newDate = tienda.name.split("/");
          labels.push(newDate[1] + '/' + newDate[0]);
        })
        this.setState({ data: datos.reverse(), labels: labels.reverse() });
      })
      .catch(function (response) {
        //console.log(response[0]);
      });
  }
  componentDidMount() {
    this.peticionAxios();
  }
 
  componentDidUpdate(prevProps) {
    if (this.props.loggedstate.vars.mes !== false){
      this.props.enviarmes(false);
    }
    if (prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
      this.peticionAxios();
    }
  }

  getOption = () => {
    const options = {
      toolbox: {
        show: true,
        itemSize: 30,
        feature: {
          magicType: { type: ['line', 'bar'], iconStyle: { borderWidth: 3 } },
          restore: { iconStyle: { borderWidth: 3 } },
          saveAsImage: { iconStyle: { borderWidth: 3 } }
        }
      },
      visualMap: {
        orient: 'horizontal',
        left: 'center',
        top: 40,
        min: 0,
        max: 100,
        text: ['Excelente', 'Deficiente'],
        // Map the score column to color
        dimension: 1,
        inRange: {
          color: ['#FD665F', '#FFCE34', '#65B581']
        }
      },
      grid: {
        left: 10,
        right: 40,
        bottom: 5,
        top: 80,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.state.labels,
        axisLabel:{
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}%',
          margin: 0,
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      series: [
        {
          type: 'line',
          data: this.state.data,
          lineStyle: {
            width: 10
          },
          markPoint: {
            data: [
              { type: 'max', name: 'Max', symbol: 'arrow', symbolSize:[35,35],  label: {formatter: '{c}%', color: '#000', fontWeight: 'bold', fontSize: 14, verticalAlign: 'bottom'}, itemStyle: {color: '#00BB00'} },
              { type: 'min', name: 'Min', symbol: 'arrow', symbolSize:[35,35], label: {formatter: '{c}%',  color: '#000', fontWeight: 'bold', fontSize: 14, verticalAlign: 'top'}, symbolRotate: 180, itemStyle: {color: '#FF0000'} }
            ],
            animationDelay: 500
          },
          markLine: {
            data: [{ type: 'average', name: 'Promedio' }]
          }
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: 0,
          minSpan: 5
        },
        {
          type: 'slider',
          xAxisIndex: 0,
          minSpan: 5,
          bottom: 50
        }
      ]
    }
    return options;
  }
    
    render() {
        return (
            <div id="pantalla6" className="contenedorgrafica">
                <ReactEcharts 
                option={this.getOption()}
                className="grafica"
                style={style}
                notMerge={false}
                lazyUpdate={true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
  return {
      enviarmes: (mes) => dispatch({
          type: 'SET_MES', mes: mes
      })
  }
}
const mapStateToProps = state => ({
  loggedstate: state
})
export default connect(mapStateToProps,mapDispatchToProps)(Pantalla6);