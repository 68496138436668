import { Provider, useSelector } from 'react-redux';
import store from "./store";
import './App.css';
import Splash from './components/LogoInicio';
import Landing from './components/Landing';
import Login from './components/Login';
import Footer from './components/Footer';
import Pantalla0 from './pantallas/Pantalla0';
import Pantalla1 from './pantallas/Pantalla1';
import Pantalla2 from './pantallas/Pantalla2';
import Pantalla3 from './pantallas/Pantalla3';
import Pantalla4 from './pantallas/Pantalla4';
import Pantalla5 from './pantallas/Pantalla5';
import Pantalla6 from './pantallas/Pantalla6';
import Pantalla7 from './pantallas/Pantalla7';
import Pantalla8 from './pantallas/Pantalla8';
import Pantalla9 from './pantallas/Pantalla9';
import Pantalla10 from './pantallas/Pantalla10';
import Pantalla11 from './pantallas/Pantalla11';
import Pantalla12 from './pantallas/Pantalla12';
import Pantalla13 from './pantallas/Pantalla13';
import Pantalla14 from './pantallas/Pantalla14';
import Pantalla15 from './pantallas/Pantalla15';
import Pantalla16 from './pantallas/Pantalla16';
import Pantalla17 from './pantallas/Pantalla17';
import Pantalla17b from './pantallas/Pantalla17b';
import Reportes from './pantallas/Reportes';
import PantallaTiendas3Meses from './pantallas/PantallaTiendas3Meses';
import PantallaPreguntas3Meses from './pantallas/PantallaPreguntas3Meses';
//Mock up
import Regiones from './components/Regiones';
import Meses from './components/Meses';
//----------
import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function Content() {
  const login = useSelector(state => state.login.logged);
  if (login) {
    return (
      <Fragment>
        <div className="contenedor">

            <Routes>
              <Route path=":cliente" element={<Pantalla0 />} />
              <Route path=":cliente/" element={<Pantalla0 />} />
              <Route path=":cliente/1" element={<Pantalla1 />} />
              <Route path="/:cliente/2" element={<Pantalla2 />} />
              <Route path="/:cliente/2b" element={<PantallaTiendas3Meses />} />
              <Route path="/:cliente/3" element={<Pantalla3 />} />
              <Route path="/:cliente/4" element={<Pantalla4 />} />
              <Route path="/:cliente/5" element={<Pantalla5 />} />
              <Route path="/:cliente/6" element={<Pantalla6 />} />
              <Route path="/:cliente/7" element={<Pantalla7 />} />
              <Route path="/:cliente/7b" element={<PantallaPreguntas3Meses />} />
              <Route path="/:cliente/8" element={<Pantalla8 />} />
              <Route path="/:cliente/9" element={<Pantalla9 />} />
              <Route path="/:cliente/10" element={<Pantalla10 />} />
              <Route path="/:cliente/11" element={<Pantalla11 />} />
              <Route path="/:cliente/12" element={<Pantalla12 />} />
              <Route path="/:cliente/13" element={<Pantalla13 />} />
              <Route path="/:cliente/14" element={<Pantalla14 />} />
              <Route path="/:cliente/15" element={<Pantalla15 />} />
              <Route path="/:cliente/16" element={<Pantalla16 />} />
              <Route path="/:cliente/17" element={<Pantalla17 />} />
              <Route path="/:cliente/17b" element={<Pantalla17b />} />
              <Route path="/:cliente/reportes" element={<Reportes />} />
              <Route absolute path="/" element={<Landing />} />
            </Routes>

        </div>
        <Footer />
      </Fragment>
    );
  } else {
    return (
      <Fragment>

          <Routes>
            <Route path="/:cliente" element={<Login />} />
            <Route absolute path="*" element={<Landing />} />
          </Routes>

      </Fragment>
    );
  }

  
  /*return (
    <Fragment>
        <div className="contenedor">

            <Routes>
              <Route path=":cliente" element={<Pantalla1 />} />
              <Route path=":cliente/1" element={<Pantalla1 />} />
              <Route path="/:cliente/2" element={<Pantalla2 />} />
              <Route path="/:cliente/3" element={<Pantalla3 />} />
              <Route path="/:cliente/4" element={<Pantalla4 />} />
              <Route path="/:cliente/5" element={<Pantalla5 />} />
              <Route path="/:cliente/6" element={<Pantalla6 />} />
              <Route path="/:cliente/7" element={<Pantalla7 />} />
              <Route path="/:cliente/8" element={<Pantalla8 />} />
              <Route path="/:cliente/9" element={<Pantalla9 />} />
              <Route path="/:cliente/10" element={<Pantalla10 />} />
              <Route path="/:cliente/11" element={<Pantalla11 />} />
              <Route path="/:cliente/12" element={<Pantalla12 />} />
              <Route path="/:cliente/13" element={<Pantalla13 />} />
              <Route path="/:cliente/14" element={<Pantalla14 />} />
              <Route path="/:cliente/15" element={<Pantalla15 />} />
              <Route path="/:cliente/16" element={<Pantalla16 />} />
              <Route path="/:cliente/17" element={<Pantalla17 />} />
              <Route path="/:cliente/17b" element={<Pantalla17b />} />
              <Route absolute path="/" element={<Landing />} />
            </Routes>

        </div>
        <Footer />
      </Fragment>
  )*/


  /*return (
    <Fragment>
      <Meses />
      <Regiones/>
    </Fragment>
  )*/
}

function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <Router>
        <Content />
        </Router>
      </Provider>
    </Fragment>
  );
}

export default App;
