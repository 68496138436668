import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

class Pantalla17 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagina: 1,
            paginas: 1,
            porpagina: 10,
            data: []
        };
        this.Construir = this.Construir.bind(this);
        this.Pager = this.Pager.bind(this);
    }
    Pager() {
        let paginas = parseInt(this.state.paginas) - 1;
        let pagina = parseInt(this.state.pagina);
        let mostrar = 5;
        let margen = 2;
        var botones = [];
        if (paginas < mostrar) {
            mostrar = paginas;
        }
        if (paginas > 1) {

            if (pagina <= margen) {
                let pagtemp = pagina - margen;
                if (pagtemp < 1) {
                    pagtemp = 1;
                }
                for (var i = pagtemp; i < pagtemp + mostrar && i <= paginas; i++) {

                    botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            } else {
                for (var i = pagina - margen; i < pagina + mostrar - margen && i <= paginas; i++) {

                    botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            }
            return (
                <div id="pager">
                    <button data-ira='1' onClick={(e) => this.ira(e)} className={('1' == pagina) ? ('activo') : (undefined)} type="button" key="inicio"> |{'<'} </button>
                    {botones}
                    <button data-ira={paginas} onClick={(e) => this.ira(e)} className={(paginas == pagina) ? ('activo') : (undefined)} type="button" key='fin'> {'>'}| </button>
                </div>
            )
        } else {
            return '';
        }

    }

    peticionAxios() {
        let formData = new FormData();
        formData.append('pantalla', 17);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        formData.append('pagina', this.state.pagina);
        let generales
        let datos = [];
        let labels = [];
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + '17.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ data: data[0].resultados, paginas: data[0].paginas, porpagina: data[0].porpagina });
                console.log(this.state.data);
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    componentDidMount() {
        //this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.pagina !== this.state.pagina) {
            this.peticionAxios();
        }
        if (prevProps.loggedstate.vars.mes !== this.props.loggedstate.vars.mes || prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
            if (this.state.pagina == 1) {
                this.peticionAxios();
            } else {
                this.setState({ pagina: 1 });
            }

        }
    }
    Construir() {
        const getComonents = (color) => Array.from({ length: 3 }, (_, i) => Math.floor(color / 16 ** (2 * i) % 16 ** 2))
        const interpolate = (arr1, arr2, percent) => arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)))
        function colors(value) {
            const StartColor = 0xFD665F; // Red
            const MiddleColor = 0xFFCE34; // Yellow
            const EndColor = 0x65B581; // Green

            let [start, end, v] = value < 50
                ? [StartColor, MiddleColor, value * 2]
                : [MiddleColor, EndColor, (value - 50) * 2]

            let interpoled = interpolate(getComonents(start), getComonents(end), v)

            return interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0).toString(16).padStart(6, '0')
        }
        let tabla = [];
        if (this.state.data.length > 0) {
            tabla = [];
            {
                this.state.data.map((cuestionario, index) => (
                    tabla.push(<Tr key={index}>
                        <Td>{cuestionario[0]}</Td>
                        <Td>{cuestionario[1]}</Td>
                        <Td>{cuestionario[2]}</Td>
                        <Td>{cuestionario[3]}</Td>
                        <Td>{cuestionario[4]}</Td>
                        <Td>{cuestionario[5]}</Td>
                        <Td>{cuestionario[6]}</Td>
                        <Td>{cuestionario[7]}</Td>
                        <Td> <span dangerouslySetInnerHTML={{ __html: cuestionario[8]}}/> </Td>
                        <Td> <span dangerouslySetInnerHTML={{ __html: cuestionario[9]}}/> </Td>
                    </Tr>)
                ))
            }
        }else{
            tabla = [<Tr key={0}><Td colspan="10">Sin resultados</Td></Tr>];
        }
        return tabla;
    }
    ira(e) {
        let irapagina = e.target.dataset.ira;
        this.setState({ pagina: irapagina });
    }
    render() {
        return (
            <div id="pantalla17" className="contenedorgrafica">
                <div id="graficas">
                    <div>
                        <h1>Calificación del Usuario de Google:</h1><span id="incumplimientos" className="azul">{(this.data)?(this.data.google):('')}</span>
                    </div>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Tienda</Th>
                                <Th>Calificación General</Th>
                                <Th>Fecha</Th>
                                <Th>Calificación</Th>
                                <Th>Tendencia vs. mes anterior</Th>
                                <Th>Núm. de opiniones</Th>
                                <Th>Núm. de publicaciones</Th>
                                <Th>Frecuencia</Th>
                                <Th>Mejores comentarios</Th>
                                <Th>Peores comentarios</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <this.Construir/>
                        </Tbody>
                    </Table>
                    <this.Pager />
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps)(Pantalla17);