import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
    flex: 1,
    height: "auto",
    width: "100%"
};

class Pantalla0 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            labels: [],
            labelsY: [],
            series: [],
            buttonLabel: "Crear Excel",
            fileUrl: null
        };
    }

    peticionAxios() {
        //traer meses
        let formData = new FormData();
        formData.append('pantalla', 0);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        let generales, datos;
        datos = [];
        let series = [];
        let labels = [];
        let labelsY = [];

        (async () => {
            const first = await axios({
                method: 'post',
                url: process.env.REACT_APP_URL + 'resultados_generales.php',
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            });

            const last = await axios({
                method: 'post',
                url: process.env.REACT_APP_URL + 'resultados_secciones.php',
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            });

            const googleapi = await axios({
                method: 'post',
                url: process.env.REACT_APP_URL + 'google_meses.php',
                data: formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            });


            let generales = first.data[0].pantalla6;
            let secciones = last.data[0].pantalla3;
            let google = googleapi.data[0].resultados;
            //console.log(secciones);

            function arrayIsEmpty(array) {
                //If it's not an array, return FALSE.
                if (!Array.isArray(array)) {
                    return false;
                }
                //If it is an array, check its length property
                if (array.length == 0) {
                    //Return TRUE if the array is empty
                    return true;
                }
                //Otherwise, return FALSE.
                return false;
            }

            datos = [];
            if (arrayIsEmpty(google)) {
                google.map(tienda => {
                    datos.push(tienda.value[1]);
                })


                series.push(
                    {
                        name: 'Resultados Google',
                        type: 'line',
                        stack: 'x',
                        data: datos,
                        symbolSize: 6,
                        lineStyle: {
                            width: 6,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                            shadowBlur: 3
                        },
                    }
                )
                labelsY.push('Resultados Google');
            }

            datos = [];
            generales.map(tienda => {
                datos.push(tienda.value);
                const newDate = tienda.name.split("/");
                labels.push(newDate[1] + '/' + newDate[0]);
            })

            series.push(
                {
                    name: 'Resultados Generales',
                    type: 'line',
                    stack: 'x',
                    label: {
                        show: true,
                        position: 'top',
                        fontSize: 14,
                        fontWeight: 'bold'
                    },
                    symbolSize: 10,
                    data: datos,
                    lineStyle: {
                        width: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 3,
                    },
                }
            )
            labelsY.push('Resultados Generales');

            Object.keys(secciones).map(function (key, index) {
                //console.log(secciones[key]);
                let cal_temp = [];
                Object.keys(secciones[key]).map(function (fecha, index) {
                    cal_temp.push(secciones[key][fecha]);
                })
                labelsY.push(key);
                series.push(
                    {
                        name: key,
                        type: 'line',
                        stack: 'x',
                        data: cal_temp,
                        symbolSize: 4,
                        lineStyle: {
                            width: 6,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                            shadowBlur: 3
                        },

                    }
                )

            })



            this.setState({ data: datos.reverse(), labelsY: labelsY.reverse(), labels: labels.reverse(), series: series.reverse() });

            //console.log(this.state.series);
        })()

    }
    componentDidMount() {
        this.peticionAxios();
    }

    componentDidUpdate(prevProps) {
        if (this.props.loggedstate.vars.mes !== false) {
            this.props.enviarmes(false);
        }
        if (prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
            this.peticionAxios();
        }
    }

    getOption = () => {
        const options = {
            toolbox: {
                show: true,
                itemSize: 30,
                feature: {
                    magicType: { type: ['stack', 'line', 'bar'], iconStyle: { borderWidth: 3 } },
                    restore: { iconStyle: { borderWidth: 3 } },
                    saveAsImage: { iconStyle: { borderWidth: 3 } }
                }
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                top: 45,
                data: this.state.labelsY,
                textStyle: {
                    fontWeight: 'bold'
                }
            },
            grid: {
                left: 20,
                right: 10,
                bottom: 30,
                top: 120,
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.state.labels,
                axisLabel: {
                    fontWeight: 'bold'
                }

            },
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: '',
                        margin: 0,
                        fontWeight: 'bold'
                    }
                },
                {
                    type: 'category',
                    data: this.state.labelsY,
                    axisLabel: {
                        fontWeight: 'bold'
                    }
                },
            ],
            series: this.state.series,
            dataZoom: [
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    minSpan: 5
                },
                {
                    type: 'slider',
                    xAxisIndex: 0,
                    minSpan: 5,
                    bottom: 50
                }
            ]
        }
        return options;
    }

    downloadExcel = async () => {
        this.setState({ buttonLabel: "Procesando" });

        // Prepare the URL
        const url = process.env.REACT_APP_URL + 'picasso.php?picasso=true';

        // Prepare the data to be sent as POST
        let formData = new FormData();
        formData.append('pantalla', 2); // Set the pantalla value
        formData.append('clienteid', this.props.loggedstate.vars.clienteid); // Fetch clienteid from the correct location
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);

        // Send the request
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        // Get the file URL from the response
        const fileUrl = process.env.REACT_APP_URL + response.data.url;

        // Update the state
        this.setState({ buttonLabel: "Descargar Excel", fileUrl });
    }

    render() {
        return (
            <div id="pantalla0" className="contenedorgrafica">
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
                {(this.props.loggedstate.login.permiso == 1) && (
                    <a 
                        href={this.state.fileUrl} 
                        onClick={this.state.fileUrl ? null : this.downloadExcel}
                        style={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            margin: '20px auto',
                            background: '#007BFF',
                            color: '#fff',
                            textDecoration: 'none',
                            textAlign: 'center',
                            borderRadius: '4px',
                        }}
                    >
                        {this.state.buttonLabel}
                    </a>
                )}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla0);