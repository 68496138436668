import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { ContactlessOutlined } from "@mui/icons-material";
const style = {
    flex: 1
  };
  

class Pantalla4 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nivel: false,
            region: false,
            botones: [],
            data: [],
            labels: [],
            pagina: 1,
            paginas: 1,
            porpagina: 10
        };
        this.Pager = this.Pager.bind(this);
        this.Botones = this.Botones.bind(this);
    }
    Pager() {
        let paginas = parseInt(this.state.paginas) - 1;
        let pagina = parseInt(this.state.pagina);
        let mostrar = 5;
        let margen = 2;
        var botones = [];
        if (paginas < mostrar) {
            mostrar = paginas;
        }
        if (paginas > 1) {

            if (pagina <= margen) {
                let pagtemp = pagina - margen;
                if (pagtemp < 1) {
                    pagtemp = 1;
                }
                for (var i = pagtemp; i < pagtemp + mostrar && i <= paginas; i++) {

                    botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            } else {
                for (var i = pagina - margen; i < pagina + mostrar - margen && i <= paginas; i++) {

                    botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            }
            return (
                <div id="pager">
                    <button data-ira='1' onClick={(e) => this.ira(e)} className={('1' == pagina) ? ('activo') : (undefined)} type="button" key="inicio"> |{'<'} </button>
                    {botones}
                    <button data-ira={paginas} onClick={(e) => this.ira(e)} className={(paginas == pagina) ? ('activo') : (undefined)} type="button" key='fin'> {'>'}| </button>
                </div>
            )
        } else {
            return '';
        }

    }
    ira(e) {
        let irapagina = e.target.dataset.ira;
        this.setState({ pagina: irapagina });
    }

    peticionAxios() {
        let formData = new FormData();
        formData.append('pantalla', 4);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        formData.append('nivel', this.state.nivel);
        formData.append('pagina', this.state.pagina);
        let generales
        let datos = [];
        let labels = [];
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'resultados_generales.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                if (this.state.botones == false) {
                    this.setState({ botones: data[0].pantalla4.botones });
                }
                if (this.state.nivel == false) {
                    this.setState({ nivel: data[0].pantalla4.botones[0].id });
                }
                
                generales = data[0].pantalla4.resultados.resultados;
                console.log(generales);
                generales.map(tienda => {

                    datos.push(tienda);
                    labels.push(tienda.name);
                })
                this.setState({ data: datos.reverse(), labels: labels.reverse(), paginas: data[0].pantalla4.resultados.paginas, porpagina: data[0].pantalla4.resultados.porpagina });
                
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.nivel !== this.state.nivel) {
            this.peticionAxios();
        }
        if (prevState.pagina !== this.state.pagina) {
            this.peticionAxios();
        }
        if (prevProps.loggedstate.vars.mes !== this.props.loggedstate.vars.mes || prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
            if (this.state.pagina == 1) {
                this.peticionAxios();
            } else {
                this.setState({ pagina: 1 });
            }

        }
    }

    Botones() {
        let botones = [];
        let botonarray = this.state.botones;
        botonarray.map(boton => {
            botones.push(<button onClick={() => this.setState({ nivel: boton.id })} className={this.state.nivel == boton.id ? ('activo') : ('')} data-id={boton.id} type="button">{boton.nombre}</button>);
        })
        return botones;
    }
    getOption = () => {
        const options = {
          toolbox: {
            show: true,
            itemSize: 30,
            feature: {
              magicType: { type: ['line', 'bar'], iconStyle: { borderWidth: 3 } },
              restore: { iconStyle: { borderWidth: 3 } },
              saveAsImage: { iconStyle: { borderWidth: 3 } }
            }
          },
          visualMap: {
            orient: 'horizontal',
            left: 'center',
            top: 40,
            min: 0,
            max: 100,
            text: ['Excelente', 'Deficiente'],
            // Map the score column to color
            dimension: 0,
            inRange: {
              color: ['#FD665F', '#FFCE34', '#65B581']
            }
          },
          grid: {
            left: 10,
            right: 15,
            bottom: 5,
            top: 80,
            containLabel: true
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b} {c}' + '%'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          yAxis: {
            type: 'category',
            data: this.state.labels,
            axisLabel: {
              formatter: function (params) {
                var newParamsName = "";
                newParamsName = params.replace(/(\S+\s*){1,4}/g, "$&\n");
                return newParamsName
              },
              fontWeight: 'bold',
              fontSize: 14,
            }
          },
          xAxis: {
            type: 'value',
            axisLabel:{
                fontWeight: 'bold',
                fontSize: 14,
              }
          },
          series: [
            {
              data: this.state.data,
              type: 'bar',
              label: {
                show: true,
                position: 'inside',
                color: "#333",
                fontSize: 22,
                fontWeight: 'bold',
                formatter: '{c}' + '%'
              },
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              }
            }
          ]
        }
        return options;
      }

    render() {
        return (
            <div id="pantalla4" className="contenedorgrafica">
                <div id="pager" className="botonera">
                    <this.Botones />
                </div>
                <ReactEcharts
                    option={this.getOption()}
                    className="grafica"
                    style={style}
                    notMerge={false}
                    lazyUpdate={true}
                />
                <this.Pager />

            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla4);