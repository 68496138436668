import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
  flex: 1,
  height: "auto",
  width: "100%"
};




class Pantalla1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      labels: []
    };
  }

  peticionAxios() {
    //traer meses
    let formData = new FormData();
    formData.append('pantalla', 1);
    formData.append('clienteid', this.props.loggedstate.vars.clienteid);
    formData.append('usuario', this.props.loggedstate.login.user);
    formData.append('permiso', this.props.loggedstate.login.permiso);
    formData.append('region', this.props.loggedstate.vars.region);
    formData.append('mes', this.props.loggedstate.vars.mes);
    formData.append('week', this.props.loggedstate.vars.week);
    formData.append('startdate', this.props.loggedstate.vars.startdate);
    let generales, datos;
    let labels = [];

    (async () => {
      const first = await axios({
        method: 'post',
        url: process.env.REACT_APP_URL + 'resultados_generales.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      });

      const last = await axios({
        method: 'post',
        url: process.env.REACT_APP_URL + 'resultados_secciones.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      });
      generales = first.data[0].pantalla1;
        labels.push(generales.name);
        datos = [generales];
        
        let secciones = last.data[0].pantalla1;   
        secciones.map(seccion => {
          datos.push(seccion);
          labels.push(seccion.name);
        })
        this.setState({ data: datos.reverse(), labels: labels.reverse() });
    })()

  }
  getOption = () => {
    const options = {
      toolbox: {
        show: true,
        itemSize: 30,
        feature: {
          magicType: { type: ['line', 'bar'], iconStyle: { borderWidth: 3 } },
          restore: { iconStyle: { borderWidth: 3 } },
          saveAsImage: { iconStyle: { borderWidth: 3 } }
        }
      },
      visualMap: {
        orient: 'horizontal',
        left: 'center',
        top: 40,
        min: 0,
        max: 100,
        text: ['Excelente', 'Deficiente'],
        // Map the score column to color
        dimension: 0,
        inRange: {
          color: ['#FD665F', '#FFCE34', '#65B581']
        }
      },
      grid: {
        left: 10,
        right: 15,
        bottom: 5,
        top: 80,
        containLabel: true
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} {c}' + '%'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      yAxis: {
        type: 'category',
        data: this.state.labels,
        axisLabel: {
          formatter: function (params) {
            var newParamsName = "";
            newParamsName = params.replace(/(\S+\s*){1,3}/g, "$&\n");
            return newParamsName
          },
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      xAxis: {
        type: 'value',
        axisLabel:{
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      series: [
        {
          data: this.state.data,
          type: 'bar',
          label: {
            show: true,
            position: 'inside',
            color: "#333",
            fontSize: 22,
            fontWeight: 'bold',
            formatter: '{c}' + '%'
          },
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    }
    return options;
  }
  componentDidMount() {
    this.peticionAxios();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loggedstate.vars.mes !== this.props.loggedstate.vars.mes || prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region || prevProps.loggedstate.vars.week !== this.props.loggedstate.vars.week) {
      this.peticionAxios();
    }
  }

  render() {
    return (
      <div id="pantalla1" className="contenedorgrafica">
        <ReactEcharts
          className="grafica"
          style={style}
          option={this.getOption()}
          notMerge={false}
          lazyUpdate={false}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  loggedstate: state
})
export default connect(mapStateToProps,)(Pantalla1);