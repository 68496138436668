import React, { Component} from "react";

class Pantalla11 extends Component {
    render() {
        return (
            <div id="pantalla11" className="contenedorgrafica">
                contenedor 11
            </div>
        );
    }
}
export default Pantalla11;