import React, { Component} from "react";

class Pantalla12 extends Component {
    render() {
        return (
            <div id="pantalla12" className="contenedorgrafica">
                contenedor 12
            </div>
        );
    }
}
export default Pantalla12;