import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { FormErrors } from './FormErrors.js';
import Splash from './LogoInicio';
import { withRouter } from './withRouter';
import { ReactComponent as Logo } from '../img/logo_blika.svg';
import DangerousIcon from '@mui/icons-material/Dangerous';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      token: false,
      idcliente: '',
      formErrors: { idcliente: '' },
      idclienteValid: false,
      formValid: false,
      mensaje: false,
      //cliente: props.params.cliente,
      datoscliente: {
        id: false,
        nombre: false,
        status: false,
        modulos: false
      }
    };
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      { [name]: value },
      () => { this.validateField(name, value) });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let idclienteValid = this.state.idclienteValid;

    switch (fieldName) {
      case 'idcliente':
        idclienteValid = value.length >= 4;
        fieldValidationErrors.idcliente = idclienteValid ? '' : 'El ID debe contenener como Mínimo 4 caracteres';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      idclienteValid: idclienteValid,
    }, this.validateForm);
  }
  validateForm() {
    this.setState({ formValid: this.state.idclienteValid });
  }
  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }
  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();


    let formData = new FormData();
    formData.append('cliente', this.state.idcliente)

    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'getclient.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        console.log(data[0].resultados);
        this.setState({ success: data[0].resultados });
        if (this.state.success === 1) {
          //this.props.navigate('/'+this.state.idcliente);
          this.setState({ mensaje: 'Este cliente está deshabilitado, por favor contacte al administrador' });
        }
        if (this.state.success === 2) {
          this.props.navigate('/' + this.state.idcliente);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }

  componentDidMount() {
    this.props.salir();
  }

  render() {
    return (
      <Fragment>
        {<Splash />}
        <div id="login" className="wrapper landing">
          <Logo className="logo" />
          <DangerousIcon className="danger" />
          <div id="leyenda">Por favor id de cliente que desea accesar respetando mayúsculas y minúsculas y sin espacios (ej: <strong>IDdeCliente</strong>) </div>

          <form onSubmit={e => this.handleFormSubmit(e)}>
            <div>
              <input autoCorrect="off" autoCapitalize="none" className={`form-control ${this.errorClass(this.state.formErrors.idcliente)}`} name="idcliente" value={this.state.idcliente} onChange={(event) => this.handleUserInput(event)} type="idcliente" placeholder="ID del cliente" /> {this.state.success === 0 && (<p className="has-error">Este ID no está registrado</p>)} {this.state.mensaje ? (<p className="has-error">Este cliente está deshabilitado, por favor consulte al administrador</p>) : ('')}
            </div>
            <div>
              <button type="submit" disabled={!this.state.formValid}>IR</button>
            </div>
            <div>
            </div>
          </form>
          <h3>Instalación</h3>

          <FormErrors formErrors={this.state.formErrors} />
          <Tabs id="instrucciones">
            <TabList>
              <Tab><h3><AndroidIcon /> Android</h3></Tab>
              <Tab><h3><AppleIcon /> iOS</h3></Tab>
            </TabList>

            <TabPanel>
            <ul>
                <li>Vete al botón menú (el de los tres puntos)</li>
                <li>Selecciona la opción de Añadir a pantalla de inicio.</li>
                <li>Selecciona el nombre con el que quieres enviarla.</li>
              </ul>
            </TabPanel>
            <TabPanel>
            <ul>
                <li>Haz clic sobre el botón de compartir en Safari.</li>
                <li>Haz clic sobre “Añadir a la pantalla de inicio”</li>
                <li>Verifica que el nombre que aparece en la pantalla de inicio te conviene. Modifícalo si necesario y haz clic sobre “Añadir”.</li>
              </ul>
            </TabPanel>
          </Tabs>

        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loggedstate: state
})


const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    entrar: (userid) => dispatch({ type: 'LOG_IN', userid: userid }),
    salir: () => dispatch({ type: 'LOG_OUT' }),
    dispatch,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing));