import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

var option;
const style = {
  height: "70vh",
  width: "100%"
};

option = {
  toolbox: {
    show: true,
    itemSize: 30,
    feature: {
      magicType: { type: ['line', 'bar'], iconStyle: { borderWidth: 3 } },
      restore: { iconStyle: { borderWidth: 3 } },
      saveAsImage: { iconStyle: { borderWidth: 3 } }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
    }
  },
  legend: {
    orient: 'vertical',
    left: 'left',
  },
  grid: {
    top: 150,
    containLabel: true,
    bottom: 10,
  },
  xAxis: {
    type: 'value',
    axisLabel:{
      fontWeight: 'bold',
      fontSize: 14,
    }
  },
  yAxis: {
    type: 'category',
    data: ['¿Cómo fue tu experiencia en la tienda?', '¿Regresarías a la tienda?', '¿Nos recomendarías?', '¿Qué opinión tienes del producto que adquiriste?'],
    axisLabel: {
      interval: 0,
      rotate: 0,
      formatter: function (value) {
        return value.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,2}\b/g).join("\n");
      },
      fontWeight: 'bold',
          fontSize: 14,
    }
  },
  series: [
    {
      
      name: 'Regular/Pocas veces',
      type: 'bar',
      stack: 'total',
      color: '#fac857',
      label: {
        show: true,
        formatter: '{c}%',
      },
      emphasis: {
        focus: 'series'
      },
      data: [-17, -6.5, -19.4, -1]
    },
    {
      name: 'Mala/Nunca',
      type: 'bar',
      stack: 'total',
      color: '#ef6666',
      label: {
        show: true,
        formatter: '{c}%',
      },
      emphasis: {
        focus: 'series'
      },
      data: [-34.1, -16.6, -15.4, 1]
    },
    {
      name: 'Buena/Algunas Veces',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        formatter: '{c}%',
      },
      emphasis: {
        focus: 'series'
      },
      data: [21.3, 28.3, 18.6, 34.4]
    },
    {
      name: 'Excelente/Siempre',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        formatter: '{c}%',
      },
      emphasis: {
        focus: 'series'
      },
      data: [27.6, 48.7, 46.6, 64.2]
    }
  ],
};

class Pantalla16 extends Component {
  render() {
    return (
      <div id="pantalla16" className="contenedorgrafica">
        <ReactEcharts option={option} style={style} />
      </div>
    );
  }
}
export default Pantalla16;