import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
class Meses extends Component {

    constructor(props) {
        super(props);
        this.enviarmes = this.props.enviarmes.bind(this);
        this.enviarweek = this.props.enviarweek.bind(this);
    }

    peticionAxios() {
        //traer meses
        let formData = new FormData();
        formData.append('cliente', this.props.loggedstate.vars.clienteid);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'getMonths.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.props.montarmeses(data.meses, data.meses[0].mes);
            })
            .catch(function (response) {
                console.log(response);
            });
    }

    axiosactivos() {
        //traer activos
        let formData2 = new FormData();
        formData2.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData2.append('usuario', this.props.loggedstate.login.user);
        formData2.append('permiso', this.props.loggedstate.login.permiso);
        formData2.append('mes', this.props.loggedstate.vars.mes);

        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'resultadosactivos.php',
            data: formData2,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.props.montaractivos(data[0].activos);
            })
            .catch(function (response) {
                console.log(response[0]);
            });
    }

    componentDidMount() {
        this.peticionAxios();
        this.axiosactivos();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loggedstate.vars.mes !== this.props.loggedstate.vars.mes) {
            this.axiosactivos();
        }
    }

    renderMeses = () => {
        let menumeses = [];
    }

    render() {
        let ruta = this.props.loggedstate;
        let anio = 0;
        return (
            <div id="meses">
                <button key={0} onClick={() => this.enviarmes(false)} className={(ruta.vars.mes == false && ruta.vars.week == false) ? ('activo') : ('')}>TODO</button>
                {
                    (ruta.vars.meses) ? (

                        ruta.vars.meses.map(key => {

                            if (anio == 0) {
                                anio = key.anio;
                            }

                            let styleanio = '';

                            if (anio != key.anio) {
                                styleanio = ' next';
                            }

                            return (<div key={'mes' + key.mes} className="mes">
                                <button key={key.mes} onClick={() => this.enviarmes(key.mes)} className={(key.mes == ruta.vars.mes) ? ('activo' + styleanio) : ('' + styleanio)} >{ruta.vars[ruta.vars.idioma].meses[key.mes]}</button>
                                {
                                    ruta.vars.clienteid == 93 && (
                                        <div className="semanas">
                                            {
                                                key.weeks && key.weeks.map(semana => (
                                                    (semana.active) ? (
                                                        <button
                                                            key={'semana' + semana.week}
                                                            onClick={() => this.enviarweek(semana.week, semana.start)}
                                                            className={(semana.week == ruta.vars.week) ? ('activo') : ('')}
                                                        >
                                                            {`S ${semana.week}`}
                                                        </button>
                                                    ) : (
                                                        <div key={'semana' + semana.week} className="semanainactiva">
                                                            {`S ${semana.week}`}
                                                        </div>
                                                    )
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>)

                        }
                        )
                    ) : (false)

                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedstate: state
})


const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        montaractivos: (activos) => dispatch({
            type: 'SET_ACTIVOS', activos: activos
        }),
        montarmeses: (meses, mes) => {
            console.log('montando meses', meses);
            console.log('montando mes', mes);
            dispatch({
                type: 'SET_MESES', meses: meses, mes: mes
            })
        },
        enviarmes: (mes) => {
            console.log('enviando mes', mes);
            dispatch({
                type: 'SET_MES',
                mes: mes
            });
        },
        enviarweek: (week, startdate) => dispatch({
            type: 'SET_WEEK',
            week: week,
            startdate: startdate
        })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Meses);