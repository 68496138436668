import React, { Component} from "react";

class Pantalla14 extends Component {
    render() {
        return (
            <div id="pantalla14" className="contenedorgrafica">
                contenedor 14
            </div>
        );
    }
}
export default Pantalla14;