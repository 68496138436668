import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CurrencyYenTwoTone, IndeterminateCheckBoxRounded } from "@mui/icons-material";

class Pantalla17 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            labels: [],
            regiones: []
        };
    }



    peticionAxios() {
        let formData = new FormData();
        formData.append('pantalla', 17);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        formData.append('pagina', this.state.pagina);
        let datos = [];
        let labels = [];
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'google_meses.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                let currentdata = this.state.data;
                datos = data[0].resultados;
                if (currentdata.length !== 0) {
                    this.setState({ data: currentdata.concat([datos.reverse()]) });
                } else {
                    this.setState({ data: [datos.reverse()] });
                }
                datos.map(mes => {
                    labels.push(mes.name);
                })
                this.setState({ labels: labels.reverse() });
                let regionestemp = this.state.regiones;
                regionestemp.push(data[0].region);
                this.setState({ regiones: regionestemp });
                console.log(this.state.regiones)
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
            this.peticionAxios();
        }
    }
    

    render() {
        const getComponents = (color) => Array.from({ length: 3 }, (_, i) => Math.floor(color / 16 ** (2 * i) % 16 ** 2))

        // interpolate arrays by component
        const interpolate = (arr1, arr2, percent) => arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)))

        function colors(value) {
            const StartColor = 0xFD665F; // Red
            const MiddleColor = 0xFFCE34; // Yellow
            const EndColor = 0x65B581; // Green

            let [start, end, v] = value < 50
                ? [StartColor, MiddleColor, value * 2]
                : [MiddleColor, EndColor, (value - 50) * 2]

            let interpoled = interpolate(getComponents(start), getComponents(end), v)

            return interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0).toString(16).padStart(6, '0')
        }

        const Head = () => {
            const meses = [<Th key='zero'></Th>];
            this.state.labels.map(mes => {
                const newDate = mes.split("/");

                meses.push(<Th key={mes}>{newDate[1] + '/' + newDate[0]}</Th>)
            })
            return <Tr>{meses}</Tr>
        }
        const Lines = () => {
            let linea = [];
            let datoslinea = [];
            this.state.data.map((regiones, index) => {
                datoslinea = [];
                regiones.map(region => {
                    datoslinea.push(<Td style={{backgroundColor: "#" + colors(region.value[1])}}>{region.value[0]}/5★ - {region.value[1]}%</Td>)
                })
                linea.push(<Tr><Td>{this.state.regiones[index]}</Td>{datoslinea}</Tr>)
            })
            return linea;
        }
        return (
            <div id="pantalla17b" className="contenedorgrafica">
                <div id="graficas">
                    <div>
                        <h1>Histórico de calificaciones del usuario de Google</h1>
                    </div>
                    <Table>
                        <Thead>
                            <Head />
                        </Thead>
                        <Tbody>
                            <Lines/>
                        </Tbody>
                    </Table>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps)(Pantalla17);