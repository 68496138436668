import React, { Component} from "react";

class Pantalla9 extends Component {
    render() {
        return (
            <div id="pantalla9" className="contenedorgrafica">
                contenedor 9
            </div>
        );
    }
}
export default Pantalla9;