import {
    LOG_OUT,
    SET_VARS,
    SET_REGION,
    SET_REGIONES,
    SET_MESES,
    SET_MES,
    SET_ACTIVOS,
    SET_LANG,
    SET_WEEK
} from "../types";

//cada reducer tiene su propio state
const initialState = {
    /*clienteid: 32,
    cliente: "Tiendas 3B",
    slug: 'tiendas3b',*/
    clienteid: false,
    cliente: false,
    slug: false,
    meses: false,
    mes: false,
    week: false,
    startdate: false,
    rango: false,
    region: false,
    nombreregion: false,
    modulos: false,
    regiones: false,
    activos: false,
    idioma:'es',
    es:{
        botoneslang:{
            es: 'Español',
            en: 'Inglés'
        },
        meses:{
            1: 'ENE',
            2: 'FEB',
            3: 'MAR',
            4: 'ABR',
            5: 'MAY',
            6: 'JUN',
            7: 'JUL',
            8: 'AGO',
            9: 'SEP',
            10: 'OCT',
            11: 'NOV',
            12: 'DIC'
        }
    },
    en:{
        botoneslang:{
            es: 'Spanish',
            en: 'English'
        },
        meses:{
            1: 'JAN',
            2: 'FEB',
            3: 'MAR',
            4: 'APR',
            5: 'MAY',
            6: 'JUN',
            7: 'JUL',
            8: 'AUG',
            9: 'SEP',
            10: 'OCT',
            11: 'NOV',
            12: 'DEC'
        }
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_VARS:
            return {
                ...state,
                clienteid: action.clienteid,
                cliente: action.cliente,
                modulos: action.modulos,
                slug: action.slug
            };
            break;
        case SET_REGIONES:
            return {
                ...state,
                regiones: action.regiones
            };
            break;
        case SET_REGION:
            return {
                ...state,
                region: action.region,
                nombreregion: action.nombreregion
            };
            break;
        case SET_MESES:
            return {
                ...state,
                meses: action.meses,
                mes: action.mes
            };
            break;
        case SET_MES:
            return {
                ...state,
                mes: action.mes,
                week: false,
                startdate: false
            };
            break;
        case SET_WEEK:
            return {
                ...state,
                mes: false,
                week: action.week,
                startdate: action.startdate
            };
            break;
        case SET_ACTIVOS:
            return {
                ...state,
                activos: action.activos
            };
            break;
        case SET_LANG:
            return {
                ...state,
                idioma: action.idioma
            };
            break;
        case LOG_OUT:
            return {
                ...state,
                clienteid: false,
                cliente: false,
                slug: false,
                meses: false,
                mes: false,
                rango: false,
                region: false,
                nombreregion: false,
                modulos: false,
                activos: false,
                regiones: false
            };
            break;
        default:
            return state;

    }
};