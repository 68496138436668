import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
  flex: 1
};

const Pantalla2 = (props) => {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [paginas, setPaginas] = useState(1);
  const [porpagina, setPorpagina] = useState(10);
  const [buttonLabel, setButtonLabel] = useState("Crear Excel");
  const [fileUrl, setFileUrl] = useState(null);

  const Pager = () => {
    let paginasCount = parseInt(paginas) - 1;
    let paginaCount = parseInt(pagina);
    let mostrar = 5;
    let margen = 2;
    var botones = [];
    if (paginasCount < mostrar) {
      mostrar = paginasCount;
    }
    if (paginasCount > 1) {
      if (paginaCount <= margen) {
        let pagtemp = paginaCount - margen;
        if (pagtemp < 1) {
          pagtemp = 1;
        }
        for (var i = pagtemp; i < pagtemp + mostrar && i <= paginasCount; i++) {
          botones.push(<button data-ira={i} onClick={(e) => ira(e)} className={(i == paginaCount) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
        }
      } else {
        for (var i = paginaCount - margen; i < paginaCount + mostrar - margen && i <= paginasCount; i++) {
          botones.push(<button data-ira={i} onClick={(e) => ira(e)} className={(i == paginaCount) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
        }
      }
      return (
        <div id="pager">
          <button data-ira='1' onClick={(e) => ira(e)} className={('1' == paginaCount) ? ('activo') : (undefined)} type="button" key="inicio"> |{'<'} </button>
          {botones}
          <button data-ira={paginasCount} onClick={(e) => ira(e)} className={(paginasCount == paginaCount) ? ('activo') : (undefined)} type="button" key='fin'> {'>'}| </button>
        </div>
      )
    } else {
      return '';
    }
  }

  const ira = (e) => {
    let irapagina = e.target.dataset.ira;
    setPagina(irapagina);
  }

  const peticionAxios = () => {
    //traer meses
    let formData = new FormData();
    formData.append('pantalla', 2);
    formData.append('clienteid', props.loggedstate.vars.clienteid);
    formData.append('usuario', props.loggedstate.login.user);
    formData.append('permiso', props.loggedstate.login.permiso);
    formData.append('region', props.loggedstate.vars.region);
    formData.append('mes', props.loggedstate.vars.mes);
    formData.append('week', props.loggedstate.vars.week);
    formData.append('startdate', props.loggedstate.vars.startdate);
    formData.append('pagina', pagina);
    let generales;
    let datos = [];
    let labels = [];
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'resultados_generales.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        generales = data[0].pantalla2.resultados;

        generales.map(tienda => {
          datos.push(tienda);
          labels.push(tienda.name);
        })
        setData(datos.reverse());
        setLabels(labels.reverse());
        setPaginas(data[0].pantalla2.paginas);
        setPorpagina(data[0].pantalla2.porpagina);
      })
      .catch(function (response) {
        //console.log(response[0]);
      });
  }

  const getOption = () => {
    const options = {
      toolbox: {
        show: true,
        itemSize: 30,
        feature: {
          magicType: { type: ['line', 'bar'], iconStyle: { borderWidth: 3 } },
          restore: { iconStyle: { borderWidth: 3 } },
          saveAsImage: { iconStyle: { borderWidth: 3 } }
        }
      },
      visualMap: {
        orient: 'horizontal',
        left: 'center',
        top: 40,
        min: 0,
        max: 100,
        text: ['Excelente', 'Deficiente'],
        // Map the score column to color
        dimension: 0,
        inRange: {
          color: ['#FD665F', '#FFCE34', '#65B581']
        }
      },
      grid: {
        left: 10,
        right: 15,
        bottom: 5,
        top: 80,
        containLabel: true
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} {c}' + '%'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      yAxis: {
        type: 'category',
        data: labels,
        axisLabel: {
          formatter: function (params) {
            var newParamsName = "";
            newParamsName = params.replace(/(\S+\s*){1,4}/g, "$&\n");
            return newParamsName
          },
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      series: [
        {
          data: data,
          type: 'bar',
          label: {
            show: true,
            position: 'inside',
            color: "#333",
            fontSize: 22,
            fontWeight: 'bold',
            formatter: '{c}' + '%'
          },
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    }
    return options;
  }

  useEffect(() => {
    peticionAxios();
  }, [pagina, props.loggedstate.vars.mes, props.loggedstate.vars.region, props.loggedstate.vars.week]);

  const downloadExcel = async () => {
    setButtonLabel("Procesando");
    // Prepare the URL
    const url = process.env.REACT_APP_URL + 'resultados_generales.php?excel=true';

    // Prepare the data to be sent as POST
    let formData = new FormData();
    formData.append('pantalla', 2); // Set the pantalla value
    formData.append('clienteid', props.loggedstate.vars.clienteid); // Fetch clienteid from the correct location
    formData.append('usuario', props.loggedstate.login.user);
    formData.append('permiso', props.loggedstate.login.permiso);
    formData.append('region', props.loggedstate.vars.region);
    formData.append('mes', props.loggedstate.vars.mes);

    // Send the request
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // Get the file URL from the response
    const fileUrl = process.env.REACT_APP_URL + response.data.url;

    setButtonLabel("Descargar Excel");
    setFileUrl(fileUrl);
  }

  return (
    <div id="pantalla2" className="contenedorgrafica">
      <ReactEcharts
        option={getOption()}
        className="grafica"
        style={style}
        notMerge={false}
        lazyUpdate={true}
      />
      <Pager />
      <a 
        href={fileUrl} 
        onClick={fileUrl ? null : downloadExcel}
        style={{
          display: 'inline-block',
          padding: '10px 20px',
          margin: '20px auto',
          background: '#007BFF',
          color: '#fff',
          textDecoration: 'none',
          textAlign: 'center',
          borderRadius: '4px',
        }}
      >
        {buttonLabel}
      </a>
    </div>
  );
}

const mapStateToProps = state => ({
  loggedstate: state
})

export default connect(mapStateToProps)(Pantalla2);