import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import FlakyIcon from '@mui/icons-material/Flaky';
import ReactEcharts from "echarts-for-react";

class Pantalla8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pregunta: '',
            respuestas: false,
            meses: false,
            totales: false,
            positivos: true
        };
    }

    peticionAxios() {
        let formData = new FormData();
        formData.append('pantalla', 8);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + '8.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ data: data[0].resultados });
                //console.log(this.state.data);
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    componentDidMount() {
        this.peticionAxios();
    }
    bringdata = (pregunta) => {
        this.setState({ pregunta: pregunta })
        let formData = new FormData();
        formData.append('pantalla', 8);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        formData.append('pregunta', pregunta);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + '8b.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ respuestas: data[0].respuestas, meses: data[0].meses, totales: data[0].totales });
                //console.log(this.state.respuestas);
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    render() {
        const { meses, totales, respuestas, positivos } = this.state;
        const Lines = () => {
            let linea = [];
            let seccion = 0;
            this.state.data.map((preguntas, index) => {
                if (seccion !== preguntas.idseccion) {
                    linea.push(<Tr><Th>{preguntas.seccion}</Th></Tr>);
                    seccion = preguntas.idseccion;
                }
                linea.push(<Tr><Td><button className={(this.state.pregunta == preguntas.idpregunta) ? ('active') : ('')} onClick={() => this.bringdata(preguntas.idpregunta)} >{preguntas.pregunta} </button></Td></Tr>)
            })
            return linea;
        }
        const Meses = () => {

            const data_graphs = [];
            const grafica = '';
            const style = {
                height: "150px",
                width: "150px"
            };
            if (meses) {
                Object.keys(meses).map(function (key, index) {
                    let option = {
                        
                        series: [
                            {
                                name: "Series Name",
                                type: "pie",
                                labelLine: {
                                    length: 2
                                },
                                animationDuration: 2000,
                                animationEasing: "quarticInOut",
                                radius: [10, 40],
                                center: ["50%", "50%"],
                                data: [
                                    { value: (meses[key].si), name: 'Si' },
                                    { value: (meses[key].no), name: 'No' }
                                ],
                                color: ['#c0cb0a', '#c9172e'],
                            }
                        ]
                    };
                    if (positivos) {
                        data_graphs.push(
                            <div key={key} className="graph">
                                
                                <div className="cont_grafica">
                                    <ReactEcharts option={option} style={style} className="grafica" />
                                </div>
                                <div className="pie" style={{color: '#c0cb0a'}}>
                                    si: {Math.round(((meses[key].si / (meses[key].si + meses[key].no)) * 100) * 100) / 100}%
                                </div>
                                <h4>{key}</h4>
                            </div>
                        );
                    } else {
                        data_graphs.push(
                            <div key={key} className="graph">
                                
                                <div className="cont_grafica">
                                    <ReactEcharts option={option} style={style} className="grafica" />
                                </div>
                                <div className="pie" style={{color: '#c9172e'}}>
                                    No: {Math.round(((meses[key].no / (meses[key].si + meses[key].no))) * 100)}%
                                </div>
                                <h4>{key}</h4>
                            </div>
                        );
                    }
                })
            }
            return data_graphs;
        }

        const Totales = () => {
            const totalesdiv = [];
            if (totales) {
                if (positivos) {
                    totalesdiv.push(<div className="totales" style={{color: 'rgba(101,181,129,1)'}}>Total +: {Math.round(((respuestas.si / totales) * 100) * 100) / 100}%</div>)
                } else {
                    totalesdiv.push(<div className="totales" style={{color: 'rgba(253,102,95,1)'}}>Total -: {Math.round(((respuestas.no / totales) * 100) * 100) / 100}%</div>)
                }
            }
            return totalesdiv;
        }
        return (
            <div id="pantalla8" className="contenedorgrafica">
                <div className="col">
                    <Table>
                        <Tbody>
                            <Lines />
                        </Tbody>
                    </Table>
                </div>
                <div className="col">
                    <button className={"pos-neg " + ((positivos) ? ('pos') : ('neg'))} onClick={() => ((positivos) ? (this.setState({ positivos: false })) : (this.setState({ positivos: true })))}><FlakyIcon /> Histórico de preguntas a lo largo del tiempo</button>
                    <Totales />
                    <div className="grid">
                        <Meses />
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla8);