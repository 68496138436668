import React, { Component} from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

var option;
const style = {
    height: "70vh",
    width: "100%"
  };

  option = {
    toolbox: {
      show: true,
      itemSize: 30,
      feature: {
        restore: {iconStyle: { borderWidth: 3 }},
        saveAsImage: {iconStyle: { borderWidth: 3 }}
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: 'rgba(0,0,0,0.2)',
          width: 1,
          type: 'solid'
        }
      }
    },
    legend: {
      top: 40,
      data: ['Exterior','Interior', 'Proceso de pago y devolución', 'Personal']
    },
    singleAxis: {
      top: 90,
      bottom: 50,
      axisTick: {},
      axisLabel: {},
      type: 'time',
      axisPointer: {
        animation: true,
        label: {
          show: true,
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          opacity: 0.2
        }
      }
    },
    series: [
      {
        type: 'themeRiver',
        label:{
          fontSize: 16
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 20,
            shadowColor: 'rgba(0, 0, 0, 0.8)'
          }
        },
        data: [
          ['2021/04', 90.5, 'Exterior'],
          ['2021/05', 50.5, 'Exterior'],
          ['2021/06', 88.3, 'Exterior'],
          ['2021/07', 98.5, 'Exterior'],
          ['2021/08', 73.5, 'Exterior'],
          ['2021/04', 70.5, 'Interior'],
          ['2021/05', 60.5, 'Interior'],
          ['2021/06', 80.3, 'Interior'],
          ['2021/07', 60.5, 'Interior'],
          ['2021/08', 56.4, 'Interior'],
          ['2021/04', 90.5, 'Proceso de pago y devolución'],
          ['2021/05', 80.5, 'Proceso de pago y devolución'],
          ['2021/06', 70.3, 'Proceso de pago y devolución'],
          ['2021/07', 50.5, 'Proceso de pago y devolución'],
          ['2021/08', 52.5, 'Proceso de pago y devolución'],
          ['2021/04', 90.5, 'Personal'],
          ['2021/05', 80.5, 'Personal'],
          ['2021/06', 70.3, 'Personal'],
          ['2021/07', 50.5, 'Personal'],
          ['2021/08', 52.5, 'Personal'],
        ]
      }
    ]
  };


class Pantalla10 extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: [],
        labels: []
    };
}

peticionAxios() {
    let formData = new FormData();
    formData.append('pantalla', 3);
    formData.append('clienteid', this.props.loggedstate.vars.clienteid);
    formData.append('usuario', this.props.loggedstate.login.user);
    formData.append('permiso', this.props.loggedstate.login.permiso);
    formData.append('region', this.props.loggedstate.vars.region);
    formData.append('mes', this.props.loggedstate.vars.mes);
    let secciones;
    let generales;
    let datos = [];
    let labels = [];
    axios({
        method: 'post',
        url: process.env.REACT_APP_URL + 'resultados_secciones.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
        .then(response => response.data)
        .then((data) => {
            let region = this.props.loggedstate.vars.region;
            if (region == false) {
                region = "Todas las sucursales"
            } else {
                region = this.props.loggedstate.vars.regiones[0].nombres[this.props.loggedstate.vars.region];
            }
            secciones = { [region]: data[0].pantalla3 };
            
            generales = data[0].pantalla3;

            console.log(generales);
            generales.map(tienda => {
              datos.push(tienda.value);
              labels.push(tienda.name);
            })
            this.setState({ data: datos.reverse(), labels: labels.reverse() });
            console.log(this.state);

        })
        .catch(function (response) {
            //console.log(response[0]);
        });
}
componentDidMount() {
    this.peticionAxios();
}
componentDidUpdate(prevProps) {
    if (this.props.loggedstate.vars.mes !== false) {
        this.props.enviarmes(false);
    }
    if (prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
        this.peticionAxios();
    }
}
    render() {
        return (
            <div id="pantalla10" className="contenedorgrafica">
                <ReactEcharts option={option} style={style} />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
  return {
      enviarmes: (mes) => dispatch({
          type: 'SET_MES', mes: mes
      })
  }
}
const mapStateToProps = state => ({
  loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla10);