import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { ThirtyFpsSelect } from "@mui/icons-material";

class Pantalla3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        this.Construir = this.Construir.bind(this);
    }

    peticionAxios() {
        let formData = new FormData();
        formData.append('pantalla', 3);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        formData.append('week', this.props.loggedstate.vars.week);
        formData.append('startdate', this.props.loggedstate.vars.startdate);
        let secciones
        let datos = [];
        let labels = [];
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'resultados_secciones.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                let region = this.props.loggedstate.vars.region;
                if (region == false) {
                    region = "Todas las sucursales"
                } else {
                    region = this.props.loggedstate.vars.regiones[0].nombres[this.props.loggedstate.vars.region];
                }
                secciones = { [region]: data[0].pantalla3 };

                var joined = this.state.data.concat(secciones);
                this.setState({ data: joined });

                /*generales.map(tienda => {
                  datos.push(tienda.value);
                  labels.push(tienda.name);
                })
                this.setState({ data: datos.reverse(), labels: labels.reverse() });*/
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps) {
        if (this.props.loggedstate.vars.mes !== false) {
            this.props.enviarmes(false);
        }
        if (prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
            this.peticionAxios();
        }
    }

    Construir(prevState) {
        const getComponents = (color) => Array.from({ length: 3 }, (_, i) => Math.floor(color / 16 ** (2 * i) % 16 ** 2))

        // interpolate arrays by component
        const interpolate = (arr1, arr2, percent) => arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)))

        function colors(value) {
            const StartColor = 0xFD665F; // Red
            const MiddleColor = 0xFFCE34; // Yellow
            const EndColor = 0x65B581; // Green

            let [start, end, v] = value < 50
                ? [StartColor, MiddleColor, value * 2]
                : [MiddleColor, EndColor, (value - 50) * 2]

            let interpoled = interpolate(getComponents(start), getComponents(end), v)

            return interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0).toString(16).padStart(6, '0')
        }
        //preparar datos
        if (this.state.data !== prevState.data) {


            var lineas = [];
            var regionlabel, seccionlabel;
            let seccionesarr = [];
            let mesesarr = [];
            let tablas = [];
            tablas = [];
            this.state.data.map(region => {
                Object.keys(region).map(function (key, index) {
                    regionlabel = key;
                    var secciones = region[regionlabel];
                    Object.keys(secciones).map(function (key, index) {
                        seccionlabel = key;
                        seccionesarr.indexOf(seccionlabel) === -1 && seccionesarr.push(seccionlabel);
                        var seccion = secciones[seccionlabel];
                        var linea = { [seccionlabel]: { [regionlabel]: { seccion } } };
                        lineas.push(linea);
                        Object.keys(seccion).map(function (key, index) {
                            var seccionmes = key;
                            var datames = seccion[seccionmes];
                            mesesarr.indexOf(seccionmes) === -1 && mesesarr.push(seccionmes);
                        })
                    })
                });
            })

            mesesarr.reverse();//ordenar meses para tabla
            //recorrer secciones
            let vueltas = 1;
            seccionesarr.map(seccionname => {
                console.log('vuelta: ' + vueltas);
                vueltas++;
                tablas.push(
                    <Table className="tabla_resultados">
                        <Thead>
                            <Tr>
                                <Th key={0}>{seccionname}</Th>
                                {
                                    mesesarr.map(mes => {//header meses
                                        var num = mes.split("/");
                                        var textomes = this.props.loggedstate.vars[this.props.loggedstate.vars.idioma].meses[num[1]];
                                        return (<Th key={num}>{textomes}</Th>)
                                    })
                                }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                lineas.map(linea => {

                                    var lineatemp = linea[seccionname];
                                    if (lineatemp) {
                                        var arraytemplinea = [];
                                        Object.keys(lineatemp).map(function (key, index) {
                                            var lineaname = key;//nombre de la linea (seccion)
                                            var lineadata = lineatemp[lineaname].seccion;

                                            arraytemplinea.push(<Td>{lineaname}</Td>);
                                            //obtener datos de los meses

                                            mesesarr.map(mes => {//header meses

                                                if (lineadata[mes] > 0) {
                                                    arraytemplinea.push(<Td style={{ backgroundColor: "#" + colors(lineadata[mes]) }} key={mes}>{lineadata[mes]}%</Td>);
                                                } else {
                                                    arraytemplinea.push(<Td key={mes}>{lineadata[mes]}</Td>);
                                                }
                                            })
                                        })
                                        return (<Tr>{arraytemplinea}</Tr>)
                                    }
                                })


                            }

                        </Tbody>
                    </Table>
                );
            })


            return (<div id="conttablas">{tablas}</div>);
        }
    }

    render() {
        return (
            <div id="pantalla3" className="contenedorgrafica">
                <this.Construir />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Pantalla3);