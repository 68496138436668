import React, { Component} from "react";

class Pantalla13 extends Component {
    render() {
        return (
            <div id="pantalla13" className="contenedorgrafica">
                contenedor 13
            </div>
        );
    }
}
export default Pantalla13;