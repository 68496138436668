import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import IconPhoto from '@mui/icons-material/CameraAlt';
import { ReactComponent as PdfIcon } from '../img/pdf.svg';
import Gallery from '../components/Gallery';

class Reportes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            region: false,
            data: false,
            pagina: 1,
            paginas: 1,
            porpagina: 10,
            gallery: false
        };
        this.Pager = this.Pager.bind(this);
        this.Construir = this.Construir.bind(this);
        this.handler = this.handler.bind(this)
    }
    Pager() {
        let paginas = parseInt(this.state.paginas) - 1;
        let pagina = parseInt(this.state.pagina);
        let mostrar = 5;
        let margen = 2;
        var botones = [];
        if (paginas < mostrar) {
            mostrar = paginas;
        }
        if (paginas > 1) {

            if (pagina <= margen) {
                let pagtemp = pagina - margen;
                if (pagtemp < 1) {
                    pagtemp = 1;
                }
                for (var i = pagtemp; i < pagtemp + mostrar && i <= paginas; i++) {

                    botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            } else {
                for (var i = pagina - margen; i < pagina + mostrar - margen && i <= paginas; i++) {

                    botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            }
            return (
                <div id="pager">
                    <button data-ira='1' onClick={(e) => this.ira(e)} className={('1' == pagina) ? ('activo') : (undefined)} type="button" key="inicio"> |{'<'} </button>
                    {botones}
                    <button data-ira={paginas} onClick={(e) => this.ira(e)} className={(paginas == pagina) ? ('activo') : (undefined)} type="button" key='fin'> {'>'}| </button>
                </div>
            )
        } else {
            return '';
        }

    }
    peticionAxios() {
        let formData = new FormData();
        formData.append('pantalla', 'reportes');
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        formData.append('pagina', this.state.pagina);
        let generales
        let datos = [];
        let labels = [];
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'listado_adjudicados.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {

                this.setState({ data: data.resultados, paginas: data.paginas, porpagina: data.porpagina });
                console.log(this.state.data);

            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.pagina !== this.state.pagina) {
            this.peticionAxios();
        }
        if (prevProps.loggedstate.vars.mes !== this.props.loggedstate.vars.mes || prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region) {
            if (this.state.pagina == 1) {
                this.peticionAxios();
            } else {
                this.setState({ pagina: 1 });
            }

        }
    }
    
    Construir() {
        const getComonents = (color) => Array.from({ length: 3 }, (_, i) => Math.floor(color / 16 ** (2 * i) % 16 ** 2))
        const interpolate = (arr1, arr2, percent) => arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)))
        function colors(value) {
            const StartColor = 0xFD665F; // Red
            const MiddleColor = 0xFFCE34; // Yellow
            const EndColor = 0x65B581; // Green

            let [start, end, v] = value < 50
                ? [StartColor, MiddleColor, value * 2]
                : [MiddleColor, EndColor, (value - 50) * 2]

            let interpoled = interpolate(getComonents(start), getComonents(end), v)

            return interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0).toString(16).padStart(6, '0')
        }
        let tabla = [];
        if (this.state.data) {
            {
                this.state.data.map((cuestionario, index) => (
                    tabla.push(<Tr key={cuestionario.id}>
                        <Td>{cuestionario.nombre}</Td>
                        <Td className="descargas">
                            <button className="pdf archivosico" type="button"><a href={"https://captura.blika.mx/reportes/index.php?cuestionario=" + cuestionario.id} target="_blank">Reporte </a></button>
                             / 
                            <button className="pdf archivosico" type="button"><a href={"https://captura.blika.mx/reportes/shasa.php?cuestionario=" + cuestionario.id} target="_blank">Base de datos </a></button>
                        </Td>
                    </Tr>)
                ))
            }
        }
        return tabla;
    }
    ira(e) {
        let irapagina = e.target.dataset.ira;
        this.setState({ pagina: irapagina });
    }

    handler() {
        this.setState({
          gallery: false
        })
      }

    render() {
        return (
            <div id="pantalla5" className="contenedorgrafica">
                <div>Los filtros no aplican para esta sección</div>
                {(this.state.gallery) ? (<Gallery galeria={this.state.gallery} handler = {this.handler}/>):('')}
                <Table className="tabla_resultados">
                    <Thead>
                        <Tr className="campo_tit">
                            <Th>Cuestionario</Th>
                            <Th>Descarga de reportes</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <this.Construir/>
                    </Tbody>
                </Table>
                <this.Pager />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        enviarmes: (mes) => dispatch({
            type: 'SET_MES', mes: mes
        })
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps, mapDispatchToProps)(Reportes);