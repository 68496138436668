import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';

class Regiones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autocompletar: false
    }
    this.colapsar = this.colapsar.bind(this);
    this.construir = this.construir.bind(this);
    this.seleccionarregion = this.seleccionarregion.bind(this);
  }
  arrayauto() {
    var jsonArr = [];
    for (var i = 0; i < this.props.loggedstate.vars.activos.length; i++) {
      let id = this.props.loggedstate.vars.activos[i];
      let name = this.props.loggedstate.vars.regiones[0].nombres[id];
      jsonArr.push({
        id: id,
        label: name
      });
    }
    this.setState({ autocompletar: jsonArr });
  }
  activos() {//cambiar a todos si no hay region en regiones activas
    let activos = this.props.loggedstate.vars.activos;
    let current = this.props.loggedstate.vars.region;
    if (!activos.includes(parseInt(current))) {
      this.props.enviarregion(false, false)
    }

  }
  componentDidMount() {
    this.peticionAxios();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loggedstate.vars.activos !== this.props.loggedstate.vars.activos && (this.props.loggedstate.vars.activos !== undefined && this.props.loggedstate.vars.activos !== false)) {
      this.activos();
      if (this.props.loggedstate.vars.regiones) {
        this.arrayauto();
      }
    }
    if (prevProps.loggedstate.vars.regiones !== this.props.loggedstate.vars.regiones && (this.props.loggedstate.vars.activos !== undefined && this.props.loggedstate.vars.activos !== false)) {
      this.arrayauto();
    }
    if (prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region && (this.props.loggedstate.vars.region !== undefined && this.props.loggedstate.vars.region !== false)) {
      this.removerfamilia();
      this.escalarfamilia(this.props.loggedstate.vars.region);
    }
  }

  seleccionarregion(e, region, nombreregion) {
    this.props.enviarregion(region, nombreregion);
    document.querySelector('#panregiones .cerrar').click();
  }
  removerfamilia() {
    let regiones = document.getElementById('regiones');
    let escalones = regiones.querySelectorAll('.region');
    for (var i = 0; i < escalones.length; i++) {
      escalones[i].classList.remove('familia');
    }
  }
  escalarfamilia(regionid) {
    let regiones = document.getElementById('regiones');
    let button = regiones.querySelector('[data-id="' + regionid + '"]');
    let ul = button.closest('ul');
    if (ul.id === 'regiones') {

    } else {
      let padre = ul.dataset.padre;
      let arriba = regiones.querySelector('[data-id="' + padre + '"] .graficar');
      arriba.closest('.region').classList.add('familia');
      this.escalarfamilia(padre);
    }
  }

  escalarcolapso(button) {
    let ul = button.closest('ul');
    let regiones = document.getElementById('regiones');
    if (ul.id === 'regiones') {
    } else {
      ul.classList.remove('colapsar');
      let padre = ul.dataset.padre;
      let arriba = regiones.querySelector('[data-id="' + padre + '"] .toggle');
      arriba.classList.add('activo');
      arriba.closest('.region').classList.add('escalon');
      this.escalarcolapso(arriba);
    }
  }

  colapsartodas() {
    let regiones = document.getElementById('regiones');
    let uls = regiones.querySelectorAll('ul');
    let escalones = regiones.querySelectorAll('.region');

    for (var i = 0; i < escalones.length; i++) {
      escalones[i].classList.remove('escalon');
    }

    for (var i = 0; i < uls.length; i++) {
      if (!uls[i].classList.contains('colapsar')) {
        uls[i].classList.add('colapsar');//colpasar ul
        let parent_temp = uls[i].parentElement;
        parent_temp.querySelector('.toggle').classList.remove('activo');
      }
    }
  }

  colapsar(e) {
    let button = e.currentTarget;
    let estado = button.classList.contains('activo');
    let region = button.closest('.region');
    let parent = button.closest('li');
    let child = parent.querySelector('ul');
    if (estado == false) {
      this.colapsartodas();
      this.escalarcolapso(button);
      region.classList.add('escalon');
      if (child.classList.contains('colapsar')) {//abre
        child.classList.remove('colapsar');
        button.classList.add('activo');
      } else {//cierra
        child.classList.add('colapsar');
        button.classList.remove('activo');
      }
    } else {
      child.classList.add('colapsar');
      button.classList.remove('activo');
    }

  }

  peticionAxios() {
    let formData = new FormData();
    formData.append('cliente', this.props.loggedstate.vars.clienteid);
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'arbol_regiones.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {

        this.props.montarregiones(data);

      })
      .catch(function (response) {
        console.log(response)
      });
  }

  construir($arreglo) {
    var myArray = [];
    for (const key in $arreglo) {
      //console.log(key);
      //if (this.props.loggedstate.vars.activos.includes(key)) {
      myArray[key] = (
        <li key={key} data-id={key} className={$arreglo[key].rol + ((this.props.loggedstate.vars.activos.includes(parseInt(key)) === true) ? ('') : (' inactivo'))}>
          <div className={"region "}>
            <button type="button" className={"graficar " + ((this.props.loggedstate.vars.region === $arreglo[key].id) ? ('activo') : (''))} onClick={(e) => this.seleccionarregion(e, $arreglo[key].id, this.props.loggedstate.vars.regiones[0].nombres[key])}>{this.props.loggedstate.vars.regiones[0].nombres[key]}</button>
            {($arreglo[key].rol === 'zona') ? (<button type="button" className="toggle" onClick={(e) => this.colapsar(e)}><ExpandMoreIcon className="expandir" /><ExpandLessIcon className="contraer" /></button>) : (false)}
          </div>
          {($arreglo[key].children) ? (<ul data-padre={key} className="colapsar"> {this.construir($arreglo[key].children[0])} </ul>) : (false)}

        </li>
      )
      //}
    }
    return myArray;
  }

  render() {
    let activos = this.props.loggedstate.vars.activos;
    const filterOptions = createFilterOptions({
      ignoreCase: true,
    });
    return (

      (this.props.loggedstate.vars.regiones && this.props.loggedstate.vars.activos) ? (
        <Fragment>
          <div id="current">
            <div className="titulo">selección:</div>
            <div className="current">{(this.props.loggedstate.vars.nombreregion === false) ? ('TODO') : (this.props.loggedstate.vars.nombreregion)}</div>
          </div>


          {(this.state.autocompletar) ? (
            <Autocomplete
              className="autocompletar"
              onChange={(event, newValue) => {
                if (newValue) {
                  this.props.enviarregion(newValue.id, newValue.label);
                }
              }}
              id="autocompletar"
              options={this.state.autocompletar}
              filterOptions={filterOptions}
              renderInput={(params) => <TextField {...params} label="Buscar" />}
            />
          ) : ('')}

          <ul id="regiones">
            <li key={0}>
              <div className="region">
                <button type="button" className={"graficar " + ((this.props.loggedstate.vars.region === false) ? ('activo') : (''))} onClick={(e) => this.seleccionarregion(e, false, false)}>TODO</button>
              </div>
            </li>

            {this.construir(this.props.loggedstate.vars.regiones[0].regiones)}


          </ul>
        </Fragment>
      ) : (false)

    );
  }
}

const mapStateToProps = state => ({
  loggedstate: state
})


const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    montarregiones: (regiones) => dispatch({
      type: 'SET_REGIONES', regiones: regiones
    }),
    enviarregion: (region, nombreregion) => dispatch({
      type: 'SET_REGION', region: region, nombreregion: nombreregion
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Regiones);